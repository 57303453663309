/*
  Fonts
*/

@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

/*
  CSS Reset
*/

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin, padding, and font */
* {
  margin: 0;
  padding: 0;
  font: inherit;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set height to 100% on html, body, and root */
html,
body,
#root {
  height: 100%;
}

/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture,
svg {
  max-width: 100%;
  display: block;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/*
  Vars
*/

:root {
  --primary-color: #131321;
  --bg-color: #f2e9e4;
}

body.dark {
  --primary-color: #f2e9e4;
  --bg-color: #131321;
}

/*
  Utility Classes
*/

.fw-regular {
  font-weight: 400;
}

.fw-bold {
  font-weight: 700;
}

.fs-primary-heading {
  font-size: 4.8rem;
}

/*
  General Styling
*/

/* Makes 1 rem represent 10 pixels */

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  font-family: 'Lora', serif;
  color: var(--primary-color);
  background-color: var(--bg-color);
  transition: background-color 0.15s ease-out, color 0.15s ease-out;
}

.earth {
  text-align: center;
  padding-top: 4rem;
}

.earth > button {
  background: none;
  border: none;
  cursor: pointer;
}
